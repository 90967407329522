import { createContext } from 'react';
import type { ComponentType } from 'react';

import displayName from '../../components-search-controls-adjacent/display-name';

import type { Optional } from '@skyscanner-internal/falcon-common-types/types/components';
import type { Configuration } from '@skyscanner-internal/falcon-common-types/types/hotels-components/types';

const DEFAULT_CONFIGS = {};

interface ContextProps {
  configs: Configuration;
}

const ConfigurationContext =
  // @ts-ignore missing properties
  createContext<Configuration>(DEFAULT_CONFIGS);

// eslint-disable-next-line import/prefer-default-export
export const withConfiguration = <P extends ContextProps>(
  Component: ComponentType<P>,
): ComponentType<Optional<P, 'configs'>> => {
  const Product = (props: Optional<P, 'configs'>) => (
    <ConfigurationContext.Consumer>
      {(configs) => (
        // @ts-ignore we expect that configs can be overwritten in the unit tests
        <Component configs={configs} {...(props as P)} />
      )}
    </ConfigurationContext.Consumer>
  );

  Product.displayName = displayName(Component);

  return Product;
};
